
import { AdminClient, GameClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class AppConfiguration extends Vue {

    isGalleryPublished: boolean = false;
    isChallengePublished: boolean = false;

    created() {
        Promise.all([
            //AdminClient.getGalleryStatus(),
            //AdminClient.getChallengeStatus()
        ])
        .then(xs => {
            // this.isGalleryPublished = xs[0];
            // this.isChallengePublished = xs[1];
        })
    }

    toggleGalleryConfiguration() {
        // AdminClient.toggleGalleryPublished()
        // .then(x => {
        //     this.isGalleryPublished = !this.isGalleryPublished;
        // })
    }
    toggleChallengeConfiguration() {
        // AdminClient.toggleChallengePublished()
        // .then(x => {
        //     this.isChallengePublished = !this.isChallengePublished;
        // })
    }

}
